nav {
    border-bottom: 1px solid black;
    border-left: 0px;
    border-left: 0px;
    width: 100%;
    height: 100px;
    -webkit-box-shadow: 0px 5px 43px -20px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 43px -20px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 43px -20px rgba(0,0,0,0.75);
}

.alerta-registro {
    text-align: center;
    color:#03353C;
    position: fixed;
    top: 50%; /* ajusta según la ubicación deseada desde la parte superior */
    left: 10%; /* ajusta según la ubicación deseada desde el lado izquierdo */
    width: 80%;
    z-index: 10000;
    height: 10%;
}

.lista-nav {
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40%;

}

.link-nav {
    transition: border-color 0.3s ease, color 0.3s ease;
    color: white;
    font-size: large;
    text-decoration: none;
}


.link-nav:hover {
    border-bottom: 1px solid white;
    font-weight: bold;
}

.menu_dropdown {
    position: absolute;
    top: 80%;
    padding: 15px 10px;
    z-index: 1000;
}

.menu_dropdown hr {
    border-top: 2px solid #E6E6E6;
    opacity: 1;
}

.menu_dropdown.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
}

.menu_dropdown.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: var(--speed) ease;
}

.menu_dropdown a {
    color: #E6E6E6 !important;
    text-decoration: none;
    font-family: "Nexa";
    text-transform: uppercase;
    font-size: 1.5rem;
    cursor: pointer;
}

.menu_dropdown a:hover {
    color: #E6E6E6;
    font-weight: bold;
    text-decoration: none;
    font-family: "Nexa";
    text-transform: uppercase;
    font-size: 1.6rem;
    cursor: pointer;
}

#menu_drop-1 {
    background-color: #2FAF7E;
}

#menu_drop-2 {
    background-color: #03353C;
}

#menu_drop-3 {
    background-color: #449342;
    right: 15px;
}

.navBg {
    background: linear-gradient(to right,
            #00486A 0%,
            #009C31 50%,
            #39BA9A 100%);
}

.drop {
    z-index: 10000;
}

@media (max-width: 992px) {
    nav {
        height: auto;
        padding: 20px 0 !important;
    }

    .menu_dropdown {
        top: auto;
        left: 15%;
    }
}