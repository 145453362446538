h2 {
    padding: 50px;
    font-size: 6.86rem;
    font-family: 'The-Next-Font';
    text-align: center;
}

.servicios-container {
    padding: 80px 0;
}

.cards-container {
    display: flex;
    gap: 45px;
    justify-content: center;
    position: relative;
}

.cards {
    display: flex;
    gap: 45px;
}

.card ul li {
    font-family: 'Monserrat-Medium';
    font-size: 20px;
    color: #E6E6E6;
    text-align: justify;
}

#card-1 {
    background-color: #319c5e;
}

#card-2 {
    background-color: #0c3c44;
}

#card-3 {
    background-color: #87d0b5;
}

.card {
    width: 555px;
    box-shadow: 0px 18px 16px 15px rgba(0, 0, 0, 0.92);
    padding: 20px 45px;

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 50px;
    text-align: center;
}

.card .title {
    padding: 10px;
    font-size: 25px;
    font-family: 'Lemon-Milk';
    text-transform: uppercase;
    text-align: center !important;
    margin-left: 0px !important;
}

.card p,
.cards-container ul li {
    font-family: 'Monserrat-Medium';
    font-size: 20px;
    color: #E6E6E6;
    text-align: justify;
}

/* Arrow left and right */
.cards-container::before {
    left: -1rem;
}

.cards-container::after {
    right: -1rem;
}

.cards-container::before,
.cards-container::after {
    position: absolute;
    top: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    font-size: 0;
    outline: 0;
}

.cards-container::before,
.cards-container::after {
    content: '';
    z-index: 1;
    /* background-color: #333; */
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 2.5rem;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.cards-container::before:hover,
.cards-container::after:hover {
    transform: scale(1.2);
    background-color: red;
}

.cards-container::before {
    background-image: url("../../../../public/assets/icons/left-arrow.png");
}

.cards-container::after {
    background-image: url("../../../../public/assets/icons/right-arrow.png");
}

/* ********************************* */
.servicios-container .container {
    max-width: 124rem;
    padding: 4rem 1rem;
    margin: 0 auto;
}

.swiper {
    width: 100% !important;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}


.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 555px !important;
    height: 520px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    object-fit: cover;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    content: none !important;
}



/*PROBANDO ANDO*/

/*.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 80% !important;
        transform: translateX(-80%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important;
    }
}

.slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: none !important;
}*/

@media (min-width: 1440px) {
    .swiper-button-prev {
        left: 28px !important;
    }

    .swiper-button-next {
        right: 28px !important;
    }
}

@media (min-width: 2560px) {
    .container {
        zoom: 1.5;
    }
}

@media (min-width: 3860px) {
    .container {
        zoom: 2.5;
    }
}

::-webkit-scrollbar {
    width: 1.3rem;
}

@media (max-width: 767px) {
    .servicios-container h2 {
        font-size: 2.4rem;
    }

    .card {
        padding: 20px 0 !important;
        box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.75) !important;
        border-radius: 18px;
    }

    .card .title {
        font-size: 14px !important;
        margin-left: 0px !important;
    }

    .card p,
    .card ul li {
        font-size: 12px;
    }

    .swiper {
        width: 80%;
    }

    .swiper_container {
        width: 100% !important;
        padding: 15px 50px !important;
    }

    .swiper-slide {
        width: 100% !important;
        height: 100% !important;
    }

    .slider-arrow img {
        width: 45px;
    }

    .swiper-button-prev {
        left: 10px !important;
    }

    .servicios-container .container {
        padding: 0 1rem !important;
    }
}