.container-options button {
    border: none;
    background: none;
    padding: 0;
}

.oferta-container {
    padding: 80px 0;
}

.title-right {
    font-size: 5.71rem;
    font-family: 'Nexa';
    text-align: end;
    text-transform: uppercase;
}

.title-adding {
    text-align: center;
    font-size: 5.71rem;
    font-family: 'Lemon-Milk-Light';
}

.options-services {
    display: flex;
    justify-content: center;
    padding: 80px 0;
}

.container-options {
    display: inline-flex;
    gap: 45px;
    border-radius: 70px;
    padding: 20px 40px;
    position: relative;

    background-color: #03353C;
}

.selected {
    position: absolute;
    top: 20px;
    height: calc(100% - 40px);
    background: #3cb34c;
    border-radius: 50px;
    padding: 0 30px;
    z-index: 10;
    transition: left 0.5s ease, right 0.5s ease !important;
}

.webpages,
.online-store {
    position: relative;
    z-index: 20;
}

.webpages button,
.online-store button {
    font-family: 'Nexa';
    font-size: 4.57rem;
    text-decoration: none;
    color: #e6e6e6;
    transition: text-decoration 0.3s ease;
}

.webpages button:hover,
.online-store button:hover {
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
}

.pricing {
    display: flex;
    justify-content: center;
    gap: 45px;
    flex-wrap: wrap;
}

.pricing-box {
    background-color: #04343c;
    padding: 40px 50px;
    border-radius: 50px;
    min-width: 350px;
    max-width: 480px;
    position: relative;
}

.pricing-box h3 {
    font-family: 'Lemon-Milk';
    color: #e6e6e6;
    font-size: 2.57rem;
    text-align: center;
    height: auto;
}

.pricing-box p {
    font-size: 1.3rem;
    color: #32b69a;
    text-align: center;
}

.pricing-box ul {
    list-style: outside;
}

.pricing-box ul::-webkit-scrollbar {
    width: 5px;
}

.pricing-box ul::-webkit-scrollbar-track {
    background-color: #2a6772 !important;
    border-radius: 20px;
}

.pricing-box ul::-webkit-scrollbar-thumb {
    background-color: #39BA9A !important;
    border-radius: 20px;
}

.card_ul {
    max-height: 350px;
    overflow-y: auto;
}

.pricing-box ul li {
    font-family: 'Monserrat-Medium';
    font-size: 1.3rem;
    color: #e6e6e6;
}

.pricing-box ul li hr {
    border: 1px solid #32b69a;
    margin: 9px 0;
}

.pricing-box ul li::marker {
    color: #32b69a;
}

.pricing-box .money {
    color: #FFFFFF;
}

.pricing-box .money span {
    font-size: 0.9rem;
}

.pricing-box .want-it {
    /*display: flex;
    justify-content: center;
    height: auto;
    align-items: end;*/
    display: flex;
    justify-content: center;
    height: auto;
    align-items: end;
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
}

.pricing-box .want-it button {
    background-color: #32b69a;
    padding: 5px 30px;
    color: #FFFFFF;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.pricing-box .want-it button:hover {
    transform: scale(1.1);
}

@media (max-width: 767px) {
    .oferta-container {
        padding: 0;
    }

    .oferta-container .title-right {
        font-size: 1.8rem;
    }

    .oferta-container h2 {
        font-size: 2.4rem;
    }

    .selected {
        width: 135px !important;
    }

    .selected {
        top: 15px;
        height: calc(100% - 30px);
    }

    .oferta-container .title-adding {
        font-size: 18px;
    }

    .container-options .webpages button,
    .container-options .online-store button {
        width: 115px;
        font-size: 18px;
    }

    .pricing {
        padding: 0 20px;
    }

    .pricing .pricing-box {
        min-width: 250px;
        padding: 40px 20px;
    }

    .pricing-box h3 {
        font-size: 1.2rem;
    }

    .pricing-box p {
        font-size: 0.85rem;
    }

    .pricing-box ul li {
        font-size: 0.85rem;
    }
}


@media (min-width: 767px) and (max-width: 1860px) {
    .title-right {
        font-size: 4rem;
    }

    .selected {
        width: 330px !important;
    }

    .title-adding {
        font-size: 4.2rem;
    }

    .webpages button,
    .online-store button {
        font-size: 3.6rem;
    }

    .pricing-box h3 {
        font-size: 2rem;
    }

    .pricing-box p {
        font-size: 1rem;
    }

    .pricing-box ul li {
        font-size: 1rem;
    }
}

@media (min-width: 1860px) and (max-width: 1920px) {
    .selected {
        width: 340px !important;
    }
}

@media (min-width: 1920px) {
    .selected {
        width: 410px !important;
    }
}