
.modalFooter{
    background:#03353c;
    color: white;
    display: flex;
    justify-content: center;
}



.modalBody{
    background: #03353c;
    color: white;
}

.formulario{
    margin-top: 10%;
    color:white
}

.parrafoRegistro{
    text-align: center;
    font-size: 15px;
}

.parrafoCambio{
    text-align: center;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}

.inputStyle {
    border: none; 
    margin: 0 auto; 
    padding: 0; 
    background-color: transparent; 
    border-bottom: 3px solid #2faf7e; 
    border-left: 3px solid #2faf7e; 
    border-right: 3px solid #2faf7e; 
    border-radius: 0; 
    outline: none; 
    margin-bottom: 10%;
    width: 70%;
    text-align: center;
    color:white
}

.inputStyle:focus {
    border-color: #2faf7e; 
    box-shadow: none; 
}

.inputStyle::placeholder {
    color: white; 
}

.btnGuardar{
    background: #2faf7e;
    width: 50%;
}

.btnGuardar:hover{
    background: #8bfacf;
}

.btnGuardar:disabled {
    background: white;
    color: gray;
    cursor: not-allowed;
  }

.title{
   margin-left: 35%;
}