h2 {
    font-size: 6.86rem;
    color: #E6E6E6;
}

.nosotros-container {
    margin: 0 40px;
    padding: 80px 0;
}

.oferta-software-container {
    height: 750px;
}

.solution {
    font-size: 5.71rem;
    font-family: 'Nexa';
    text-transform: uppercase;
    text-align: start;
}

.tupyme-container {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
}

.tupyme-container img {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.tupyme-container img:hover {
    transform: scale(1.2);
}

.tupyme-container .arrow {
    display: flex;
    animation: a3 .5s infinite alternate;
    width: 80px;
    height: 80px;
    background: #E6E6E6;
    border-radius: 50%;
    margin-left: 15px;
}

.punto-venta-container,
.sistema-administrativo-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: 40px;
    grid-auto-flow: dense;
    padding: 80px 0;
}

.punto-venta-left,
.sistema-administrativo-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
}

.sistema-administrativo-left {
    align-items: flex-end;
}

.punto-venta-left .punto-venta-title,
.sistema-administrativo-left .sistema-administrativo-title {
    font-family: 'The-Next-Font';
    font-size: 6.76rem;
}

.punto-venta-left p,
.sistema-administrativo-left p {
    font-family: 'Nexa';
    font-size: 3.33rem;
    text-align: start;
    text-transform: uppercase;
}

.sistema-administrativo-left p {
    text-align: end;
}

.punto-venta-right {
    display: flex;
    justify-content: end;
}

.sistema-administrativo-right {
    display: flex;
}

.punto-venta-right img,
.sistema-administrativo-right img {
    height: auto;
    object-fit: contain;
}

@keyframes a3 {
    0% {
        transform: scaleX(var(--s, 1)) translate(-4px)
    }

    100% {
        transform: scaleX(var(--s, 1)) translate(4px)
    }
}


@media (max-width: 767px) {
    .nosotros-container {
        margin: 0 !important;
        padding: 0 !important;
    }

    .nosotros-container .solution {
        width: 50% !important;
        font-size: 1.8rem;
    }

    .nosotros-container h2 {
        font-size: 2.4rem;
    }

    .tupyme-container img {
        width: 50%;
    }

    .tupyme-container .arrow {
        width: 50px;
        height: 50px;
    }

    .punto-venta-left,
    .sistema-administrativo-left {
        align-items: center;
        gap: 25px !important;
    }

    .punto-venta-left img,
    .sistema-administrativo-left img {
        width: 18%;
    }

    .punto-venta-left .punto-venta-title,
    .sistema-administrativo-left .sistema-administrativo-title {
        font-size: 1.6rem !important;
    }

    .punto-venta-left p,
    .sistema-administrativo-left p {
        font-size: 1.3rem !important;
        text-align: center;
    }

    .punto-venta-container,
    .sistema-administrativo-container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media (min-width: 767px) and (max-width: 1440px) {

    .punto-venta-right .swiper,
    .sistema-administrativo-container .swiper {
        zoom: 0.8;
    }

    .swiper-button-prev {
        left: 28px !important;
    }

    .swiper-button-next {
        right: 28px !important;
    }
}

@media (min-width: 1440px) {

    .punto-venta-left img,
    .sistema-administrativo-left img {
        width: 120px;
    }

    .punto-venta-right .swiper,
    .sistema-administrativo-container .swiper {
        zoom: 0.8;
    }
}

@media (max-width: 1860px) {
    h2 {
        font-size: 5rem;
    }

    .solution {
        font-size: 4rem;
    }

    .punto-venta-left .punto-venta-title,
    .sistema-administrativo-left .sistema-administrativo-title {
        font-size: 4.5rem;
    }

    .punto-venta-left p,
    .sistema-administrativo-left p {
        font-size: 2.8rem;
    }
}

@media (min-width: 1720px) {

    .punto-venta-right .swiper,
    .sistema-administrativo-container .swiper {
        zoom: 1;
    }
}

@media (min-width: 2800px) {
    .container {
        zoom: 1.5;
    }

    .punto-venta-right .swiper,
    .sistema-administrativo-container .swiper {
        zoom: 1.3;
    }
}