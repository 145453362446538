h3{
    font-family: 'Lemon-Milk';
    color: #e6e6e6;
    font-size: 30px;
    text-align: center;
    height: auto;
    padding: 3%;
}



h4{
    font-family: 'Lemon-Milk';
    color:white;
    text-align: center;
    padding: 5%;
    font-size: 20px;
    height: auto;
}

.descriptionList {
    height: 200px;
    max-height: 200px;
    overflow-y: auto;
    width: auto; /* Ancho automático */
    padding: 1%;
  }

.descriptionList::-webkit-scrollbar { 
    width: 5px;
  }
  
 
  .descriptionList::-webkit-scrollbar-track {
    background-color: #2a6772 !important; 
  }
  
 
  .descriptionList::-webkit-scrollbar-thumb {
    background-color: #39BA9A !important; 
  }

.modal-paquetes{
    background: linear-gradient(to right,
    #00486A 0%,
    #009C31 50%,
    #39BA9A 100%);
}

.modal-cabecera{
    background: linear-gradient(to right,
    #00486A 0%,
    #009C31 50%,
    #39BA9A 100%);
    color: white;
    width: auto;
}

.slect-container{
    width: auto;
    display: flex;
    padding: auto;
    justify-content: center;
}

.custom-select {
    width: auto;
    padding: 1%;
    margin: 10%;
    border: none;
    border-bottom: 2px solid #39BA9A; 
    border-radius: 0;
    background-color: transparent;
    outline: none; 
    color: white;
  }
  
 
  .custom-select option {
    background-color: #39BA9A; 
    color: white; 
  }

@media (max-width: 767px) {
    h3{
        font-family: 'Lemon-Milk';
        color: #e6e6e6;
        font-size: 20px;
        text-align: center;
    }
    
    
    
    h4{
        font-family: 'Lemon-Milk';
        color:white;
        text-align: center;
        padding: 5%;
        font-size: 15px;
        height: auto;
    }

    .custom-select {
        font-size: 14px;
      }

    .custom-select option {
        font-size: 10px;
        width: auto;
      }
}

