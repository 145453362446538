.planSelect h5{
    color: white;
    text-align: left;
    padding-bottom: 5%;
}

.planRow {
    display: flex;
    justify-content: space-between;
    width: 80%;
   padding: auto;
   margin: auto;
  }
  
  .planRow b {
    color: white;
  }

  .planRow p {
    color: white;
    border-bottom: 1px solid;
    width: 100%;
    text-align: end;
  }
  
.cerrar {
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    width: 18px;
    margin: 0px 10px 0px auto;
    color: white;
  }

.buttonPaypalContainer{
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: end;
}

@media screen and (max-width: 768px) {
    .planRow {
        display: flex;
        justify-content: space-between;
        width: 60%;
       padding: auto;
       margin: auto; /* Cambiar a dirección de columna en pantallas más pequeñas */
    }
  
    .planRow b, .planRow p {
      text-align: center; /* Centrar texto en pantallas más pequeñas */
    }
  
    .buttonPaypalContainer {
      margin-top: 5%; /* Ajustar el margen superior en pantallas más pequeñas */
    }
  }