h1 {
    padding: 50px;
    font-size: 6.57rem;
}

.first-header {
    position: relative;
}

.first-header img {
    width: 100%;
}

.first-screen {
    position: relative;
    text-align: center;
    height: 750px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.first-screen p {
    font-size: 2.9rem;
}

.contact {
    position: absolute;
    right: 31.425px;
    bottom: 0;
}

.contact .boton-vertical {
    font-family: 'Lemon-Milk-Light';
    font-size: 1.52rem;
    writing-mode: vertical-lr;
    text-orientation: upright;
    cursor: pointer;
    color: #e6e6e6;
    background: #2FAF7E;
    transition: transform 0.3s ease;
}

.contact .boton-vertical:hover {
    transform: scale(1.2);
}

.social-media {
    display: flex;
    flex-direction: column;
    gap: 28px;
    position: absolute;
    bottom: 0;
    left: 31.425px;
}

.link-nav {
    transition: transform 0.3s ease;
    cursor: pointer;
}

.link-nav:hover {
    transform: scale(1.2);
}

@media (max-width: 767px) {
    .first-screen p {
        font-size: 1rem !important;
    }

    .social-media {
        left: 5px;
    }

    .contact {
        right: 5px;
    }
}

@media (max-width: 992px) and (orientation: portrait) {
    .first-screen {
        height: calc(100vh - 150px);
    }

    .first-screen p {
        font-size: 2.3rem
            /* Tamaño de fuente en pantallas aún más grandes (por ejemplo, computadoras de escritorio) */
    }
}

@media (max-width: 1860px) {
    .first-header img {
        width: 80%;
    }

    .first-screen p {
        font-size: 2.2rem;
    }

    .contact .boton-vertical {
        font-size: 1rem;
    }
}