@import '~bootstrap/dist/css/bootstrap.min.css';

@font-face {
  font-family: 'Monserrat-Medium';
  src: url(../public/assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: 'Lemon-Milk';
  src: url(../public/assets/fonts/LEMONMILK-Regular.otf);
}

@font-face {
  font-family: 'Lemon-Milk-Light';
  src: url(../public/assets/fonts/LEMONMILK-Light.otf);
}

@font-face {
  font-family: 'The-Next-Font';
  src: url(../public/assets/fonts/TheNextFont.ttf);
}

@font-face {
  font-family: 'Nexa';
  src: url(../public/assets/fonts/Nexa-ExtraLight.ttf);
}

html {
  scroll-behavior: smooth;
  font-size: 14px;
}

body {
  color: white;
  background: linear-gradient(to right,
      #00486A 0%,
      #009C31 50%,
      #39BA9A 100%);
}

p{
  color: white;
}

* {
  font-family: 'Lemon-Milk';
}

/* Cambio estilos de botones por defecto bootstrap*/
.boton-vertical {
  writing-mode: vertical-lr;
  text-orientation: upright; 
  background: #009C31 !important; 
  border-color: #009C31 !important;
}