.footer-container {
    padding: 80px 0;
}

.footer-container .title-left {
    font-size: 5.71rem;
    font-family: 'Nexa';
    text-align: left;
    text-transform: uppercase;
}

.button-options {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 45px;
}

.button-options button {
    font-family: "Lemon-Milk-Light";
    font-size: 4.1rem;
    background-color: #32b69a;
    padding: 15px 100px;
    border-style: none;
    color: #E6E6E6;
    transition: transform 0.5s ease;
}

.button-options button:hover {
    transform: scale(1.04);
}

.footer-container .first-screen .social-media {
    top: 25%;
    bottom: auto;
}

@media (max-width: 767px) {
    .footer-container .title-left {
        font-size: 1.8rem !important;
    }

    .button-options button {
        font-size: 1.3rem !important;
    }

    .footer-container .first-screen .social-media {
        top: auto;
        bottom: 0;
    }

    .footer-container .first-screen {
        height: 500px;
    }
}

@media (max-width: 1860px) {
    .footer-container .title-left {
        font-size: 4rem;
    }

    .button-options button {
        font-size: 3rem;
    }
}