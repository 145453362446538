.modal-paquetes .modal-content {
    background: linear-gradient(to right,
            #00486A 0%,
            #009C31 50%,
            #39BA9A 100%);
}

.selections_container .row+.row {
    margin-top: 1rem;
}

.selections_container .row {
    gap: 15px 0;
}

.select_input {
    width: 450px;
}

.select-options {
    border: none;
    background-color: #32b69a;
    text-align: center;
    color: #E6E6E6;
}

.select-options options {
    color: black;
}